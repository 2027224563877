<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/products' }">Products</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>products:</b></h3>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Name"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <div>
                <!-- <AddPurchaseDialog @re-fresh="getProducts" /> -->
              </div>
            </div>
            <hr />
  
            <div
              v-loading="loading || is_changing"
              class="table-responsive mb-5"
            >
              <data-tables
                :data="products"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getProducts"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load products Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No Products</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Supplier Info"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span>
                        <strong>Name: </strong> {{ scope.row.ProductName }}
                    </span><br />
                    <small><strong>Added On:</strong> {{ formattedDate(scope.row.addedDate) }} </small><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Quantity Available"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span> {{ scope.row.quantityAvailable }} </span><br />
                  </template>
                </el-table-column>
                
                <el-table-column
                  label="Created By"
                  label-class-name="text-left"
                  class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
                    {{ scope.row.first_name }} {{ scope.row.last_name }}
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="150px"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                     
                      <el-tooltip
                        content="Edit"
                        placement="top"
                      >
                      <!-- :disabled="$store.state.userRoles !== 'ADMIN'" -->
                        <el-button
                          type="warning"
                          
                          @click="showEditSupplierData(scope.row)"
                          icon="el-icon-edit"
                          circle
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip
                        content="Delete"
                        placement="top"
                      >
                        <el-button
                          type="danger"
                          @click="deleteSupplier(scope.row.ProductID)"
                          icon="el-icon-delete"
                          circle
                        ></el-button>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/scale-out-component.vue";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        is_changing: false,
        showSupplierEditDialog: false,
        products: [],
        user_data: {},
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "fullname",
            value: "",
          },
        ],
        loadingError: false,
        
  
        approval_statuses: [
          {
            value: "PENDING",
            label: "Pending",
          },
          {
            value: "APPROVED",
            label: "Approved",
          },
          {
            value: "REJECTED",
            label: "Rejected",
          }
        ],
  
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
    },
  
    mounted() {
      this.getProducts();
    },
  
    methods: {

      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },
      showEditSupplierData(userData) {
        this.showSupplierEditDialog = true;
        this.user_data = userData;
      },
  
      closeEditSupplierData() {
        this.showSupplierEditDialog = false;
      },
  
      
      async getProducts() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(
            `products/get-products/${this.$store.state.company.company_id}`
          );
          if (request.data.success) {
            this.products = request.data.products;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch products now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteSupplier(userId) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Admin Supplier. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`products/${userId}`);
              if (
                request.data.success &&
                request.data.message === "USER_DELETED_SUCCESSFULLY"
              ) {
                this.getproducts();
                return this.$notify({
                  title: "Success",
                  message: "Admin Supplier deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              return this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete Admin Supplier, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
  
      async switchStatus(activation_status, userId) {
        try {
          this.is_changing = true;
          let request = await this.$http.patch(
            `products/change_is_active_status/${userId}`, {
              is_active: activation_status,
            }
          );
          if (
            request.data.success &&
            request.data.message == "USER_IS_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
          ) {
            this.getproducts();
            this.showSuccessMessage("Success", );
                return this.$notify({
                  title: "Success",
                  message: "Supplier status changed",
                  type: "success",
                });
          } else if (
            request.data.message == "ONLY_APPROVED_STAFF_CAN_BE_ACTIVATED"
          ) {
            this.showWarningMessage(
              "Approve First",
              "Only Approved Staff Members can be activated/deactivated. Please approve them first."
            );
            this.is_changing = false;
            console.log(this.is_active);
            // this.is_active = false;
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.is_changing = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change staff status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.is_changing = false;
        }
      },
  
      async changeIsApprovedStatus(userId, is_approved_status) {
        try {
          this.is_changing = true;
          let request = await this.$http.patch(
            `products/change_is_approved_status/${userId}`, {
              is_approved_status: is_approved_status,
            }
          );
          if (
            request.data.success &&
            request.data.message == "USER_IS_APPROVED_STATUS_CHANGED_SUCCESSFULLY"
          ) {
            this.getproducts();
            this.showSuccessMessage("Success", );
                return this.$notify({
                  title: "Success",
                  message: "Supplier status changed",
                  type: "success",
                });
          } else if (
            request.data.message == "ONLY_APPROVED_STAFF_CAN_BE_ACTIVATED"
          ) {
            this.showWarningMessage(
              "Approve First",
              "Only Approved Staff Members can be activated/deactivated. Please approve them first."
            );
            this.is_changing = false;
            console.log(this.is_active);
            // this.is_active = false;
          } else {
            throw "UNEXPECTED_RESPONSE";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.is_changing = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to change staff status",
            "An unexpected error occurred, please try again"
          );
        } finally {
          this.is_changing = false;
        }
      }
  
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>